import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import QuestionsAndAnswers from '../components/QuestionsAndAnswers'

import styled from 'styled-components'
import { VW, mobileVW, desktopVW } from '../styles'

const Faq = ({
  data: {
    contentfulFaqPage: {
      seoTitle,
      seoDescription,
      questionsAndAnswers
    },
  },
}) => (
  <>
    <SEO
      title={seoTitle}
      description={seoDescription}
      slug=' ' />
    <QuestionsAndAnswers data={questionsAndAnswers} />
  </>
)

export const PageQuery = graphql`
  query Faq($id: String!) {
    contentfulFaqPage(id: { eq: $id }) {
      seoTitle
      seoDescription
      slug
      questionsAndAnswers {
        intro {
          json
        }
        qa {
          json
        }
        emailIntro
        emailUs
        emailAddress
        image {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

export default Faq
